//
// General Styles
// --------------------------------------------------

html {
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;
	overflow-x: hidden !important;
}

body {
	font-family: $font-base;
	font-size: $text-base-size;
	line-height: $text-base-line-height;
	color: $color-text;
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	overflow-x: hidden !important;
}

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	max-width: 100%;
}

a img,
img {
	border: none;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;
	transition: all .2s linear;

	&:hover {
		outline: none;
		color: $color-link-hover;
		text-decoration: none;

		i {
			color: inherit;
		}
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}
}

p,
blockquote {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	border-left: 5px solid $color-gray-lighter;
	padding: 10px 0 10px 25px;
}

table {
	margin-top: $margin-base;
	margin-bottom: 15px;
	border: inherit;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: collapse;

	tr {
		border: inherit;
		border-color: inherit;

		td {
			border-top: 1px solid $color-gray-lighter !important;
			padding: 1rem !important;
			vertical-align: middle !important;
		}
	}
}

address {
	font-style: normal;
}

.gform_wrapper {

	.gform_required_legend {
		display: none;
		visibility: hidden;
	}
}

.gform_validation_errors {
	padding: 0;
	color: $color-red;
	margin-bottom: 25px;
	font-size: 14px;
	display: none;

	.gform_submission_error {
		color: $color-red;
		font-size: 24px;
		line-height: normal;
		margin-bottom: 10px;
	}

	ol {
		padding: 0;
		list-style-position: inside;
	}

	.gform_validation_error_link {
		color: $color-red;
	}
}

.validation_message {
	color: $color-red;
	clear: both;
}

form {
	.gform_body {
		display: table;
		width: 100%;
	}

	.gform_footer {
		margin-top: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.gform_hidden {
		display: none !important;
	}

	.gfield_required {
		margin-left: 5px;
		color: $color-red;
		font-weight: bold;
		transition: all .2s linear;
	}

	.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 25px;
		row-gap: 25px;

		.uploader-btn {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.uploader-filename {
				margin-right: 60px;
				min-width: 150px;
			}

			button {
				background-color: $color-primary;
				color: $color-text;
				border: 2px solid $color-primary;
				box-shadow: none;

				&:hover {
					background-color: $color-link-hover;
					color: $color-text;
					border: 2px solid $color-primary !important;
				}

				&:focus {
					border: 2px solid $color-link-hover !important;
				}
			}
		}

		.gfield_list_group {

			.gfield_list_icons {
				margin-top: 10px;
			}
		}

		.gfield {
			margin: 0;
			padding: 0;
			position: relative;
			font-size: 18px;
			line-height: normal;

			&.gfield--width-full {
				grid-column: span 2;
			}

			&.gfield_visibility_hidden {
				display: none;
			}

			&.hidden_label {
				.gfield_label {
					display: none;
				}
			}

			&.field_sublabel_above {
				margin-bottom: 20px;
			}

			&.file-input {
				display: none;
			}

			&.gfield_error {

				input,
				textarea {
					background-color: lighten($color-red, 40%);
					border: 1px solid $color-red !important;
				}

				.ts-wrapper {

					.ts-control {
						background-color: lighten($color-red, 40%);
						border: 1px solid $color-red;
					}

					input {
						border: 0 !important;
					}
				}
			}

			.gfield_label_before_complex {
				display: none;
			}

			.ginput_complex {
				display: inline-block;
				width: 100%;

				span {
					display: inline-block;
					width: 100%;

					&.name_first,
					&.ginput_left {
						width: 50%;
						padding-right: 15px;
						float: left;
					}

					&.name_last,
					&.ginput_right {
						width: 50%;
						padding-left: 15px;
						float: left;
					}

					&.address_line_1 {
						margin-bottom: 20px;
					}
				}
			}

			.gchoice {
				width: fit-content;
				transition: all .2s linear;

				&:not(:last-child) {
					margin-bottom: 4px;
				}

				input {
					visibility: hidden;
					margin: 0;
					margin-right: 0;
				}

				label {
					padding-left: 5px;
					font-weight: 400;
					position: relative;
					cursor: pointer;
					left: initial;
					top: initial;
					transition: all .2s linear;

					&::before {
						position: absolute;
						top: 50%;
						left: -20px;
						transform: translateY(-50%);
						content: "";
						width: 16px;
						height: 16px;
						border-radius: 50%;
						border: 2px solid $color-primary;
						background-color: transparent;
						transition: all .2s linear;
					}
				}

				.gfield-choice-input:checked~label {
					font-weight: 600;

					&::before {
						background-color: $color-primary !important;

					}
				}

				&:hover {

					label {
						&::before {
							background-color: $color-primary;
						}
					}
				}
			}

			.gfield_checkbox {

				.gchoice {

					label {

						&::before {
							position: absolute;
							top: 50%;
							left: -20px;
							transform: translateY(-50%);
							content: "";
							width: 16px;
							height: 16px;
							border-radius: 3px;
							border: 2px solid $color-primary;
							background-color: transparent;
							transition: all .2s linear;
						}
					}
				}
			}
		}
	}

	label,
	.gfield_label {
		font-size: .9rem;
		line-height: normal;
		color: $color-text;
		margin-bottom: .5rem;
		display: inline-block;
	}

	.input-group-addon {
		background-color: $color-gray-darker;
		color: $color-primary;
		border: 0;
	}

	fieldset {
		border: none;
	}

	select {
		padding: 8px 10px;
		background-color: white;
		height: 42px;
		color: $color-gray;
		width: 100%;
		border: 0;
		border: 1px solid $color-gray-lighter;
	}

	select[multiple] {
		height: auto;
	}

	input,
	textarea {
		width: 100%;
		border: 0;
		background-color: $color-gray-lighter;
		color: $color-gray-darker;
		padding: 15px;
		appearance: none;
		resize: none;
		font-size: $medium;
		line-height: normal;
		font-family: inherit;
		font-weight: 500 !important;
		border-radius: .2rem;

		&::placeholder {
			opacity: .5 !important;
			color: $color-primary;
			font-size: $medium !important;
			font-weight: 500 !important;
		}

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			font-weight: 500 !important;

			&::placeholder {
				color: $color-secondary;
				font-weight: 500 !important;
				opacity: 0.3;
			}
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		appearance: inherit;
	}

	input[type="search"] {
		background-color: $color-gray;
		border-radius: $rounded;
		border: solid $color-select-border 1px;
		justify-content: flex-start;
		padding: .8rem 2.95rem .8rem 1.2rem;
		font-size: $medium;
		line-height: normal;

		button {
			position: relative;
			
			i {
				position: absolute;
				right: 1.25rem;
				color: $color-primary;
				opacity: .5;
			}
		}
	}

	input[type="checkbox"] {
		appearance: checkbox;
	}

	input[type="radio"] {
		appearance: radio;
	}

	.gfield_description {
		margin: .5rem 0;
		font-size: $xsmall;
	}
}

button {
	all: unset;
}

button,
input[type="submit"],
button[type="submit"],
.product .cart .single_add_to_cart_button,
#error-template .button,
.woocommerce a.button {
	font-size: $small;
	line-height: normal;
	font-weight: 600;
	font-family: $font-base;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	padding: .6rem 1rem;
	border-radius: $rounded;
	color: $color-secondary;
	background-color: $color-tertiary;
	transition: all .3s linear;
	text-transform: lowercase;
	cursor: pointer;

	&:hover,
	&.invert,
	&.reverse {
		color: white;
		background-color: $color-secondary;

		i {
			color: inherit;
		}
	}

	&.success {
		background-color: $color-green;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-green, 5%);
		}
	}

	&.error {
		background-color: $color-red;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-red, 5%);
		}
	}
}

.container,
.container-fluid {
	position: relative;
	margin-inline: auto;
	max-width: 2560px;
}

.container {
	width: 85vw;

	@media (max-width:576px) {
		width: 90vw;
	}

	.small-container {
		width: 83%;
		margin-inline: auto;

		@media (max-width:1199px) {
			width: 93%;
		}

		@media (max-width:767px) {
			width: 100%;
		}
	}

	.smaller-container {
		width: 66%;
		margin-inline: auto;

		@media (max-width:1199px) {
			width: 83%;
		}

		@media (max-width:767px) {
			width: 100%;
		}
	}
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.page-section {
	padding-top: $margin-base;
	padding-bottom: $margin-base;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

#main-content {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.acf-map {
	width: 100%;
	height: 700px;
	border: 0;
	border: 0;
}

.highlight {
	color: #ffffff;
	background-color: $color-primary;
}

#error-template {
	.error-details {
		margin-bottom: 30px;
	}

	.error-actions {
		.button {
			&:hover {
				i {
					color: #ffffff;
				}
			}
		}
	}
}

#post-share {
	.social-share {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				i {
					font-size: 25px;
				}
			}
		}
	}
}


.loading-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	float: none;
	background-image: url('../images/ajax-loader.svg');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

#pagination {
	text-align: center;

	.navigation {
		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;

				a {
					color: #ffffff;
					text-decoration: none;
					background-color: $color-gray-dark;
					cursor: pointer;
					padding: 5px 13px;
					border-radius: 5px;
					display: block;

					&:hover {
						background-color: $color-primary;
					}
				}

				&.active {
					a {
						background-color: $color-primary;
					}
				}
			}
		}
	}
}

//selects tom select package
.ts-wrapper {

	.ts-control {
		font-size: $medium;
		line-height: normal;
		font-weight: 400;
		color: $color-primary;
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		gap: 1rem;
		background-color: $color-gray-lighter;
		transition: all .2s linear;
		border-radius: $rounded;
		padding: 0.8rem 1.2rem !important;
		padding-right: 3.2rem !important;
		position: relative;
		text-transform: uppercase;
		min-width: 3.5rem;
		width: 100%;

		&::after {
			width: 1rem;
			height: 1rem;
			min-width: 1rem;
			min-height: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: $color-gray-light;
			content: "\f107";
			font-family: $fontawesome;
			font-weight: 400;
			color: $color-text;
			font-size: .5rem;
			position: absolute;
			top: 50%;
			right: $medium;
			transform: translateY(-50%);
			transition: all .2s linear;
		}
	}

	.ts-control,
	.ts-dropdown {
		color: $color-secondary;
		font-size: 1.1rem;
		line-height: normal;
		border: 1px solid $color-gray-lighter;
		box-shadow: none;

		input {
			font-size: inherit;
			line-height: inherit;
			background: $color-gray;
		}
	}

	.ts-dropdown {
		margin-top: 0;

		.option {
			padding: .3rem 1.2rem;
			font-weight: 400;
		}

		.selected {
			color: white;
			background-color: $color-secondary;

			&.active {
				color: white;
			}
		}

		//hover
		.active {
			color: white;
			background-color: $color-secondary
		}
	}

	&.dropdown-active {

		.ts-control,
		.ts-dropdown {
			border-color: $color-gray-lighter;
		}

		.ts-control {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			background: $color-gray;

			&::after {
				transform: translateY(-50%) rotate(180deg);
			}
		}

		.ts-dropdown {
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;

			.ts-dropdown-content {
				max-height: 15rem;
			}
		}
	}
}

#cookie-notice {
	.button {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* WordPress */
.wp-caption,
.gallery-caption {
	color: $color-gray-light;
	font-size: $xsmall;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
	font-size: 14px;
}

.bypostauthor>.comment-body>.comment-meta>.comment-author .avatar {
	border: 1px solid #333;
	padding: 2px;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft {
	float: left;
	margin-right: 1.5em;
}

img.alignright {
	float: right;
	margin-left: 1.5em;
}