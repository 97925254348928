//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: $font-base;
  font-size: $regular; //20px
  line-height: $text-base-line-height;
  font-weight: 500;
  color: $color-text;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $small;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

strong, b, .bold {
  font-weight: 700;
}

.unscroll {
  overflow-y: hidden !important;
}

.is-hidden,
.hidden {
  display: none !important;
}

.invisible,
.not-visible {
  visibility: hidden;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: normal;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: 500;
  font-family: $font-base;
}

p,
.regular {
  font-size: clamp(0.9rem, 1.1vw, $regular);
  line-height: 135%;
  font-weight: 500;
  font-family: $font-base;
}

p {
  margin: .8rem 0;

  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }

  strong, b {
    font-weight: 700;
  }
}

h6,
.medium {
  font-size: clamp(1.1rem, 1.3vw, $medium);
  line-height: normal;
  font-weight: 600;
  font-family: $font-title;
}

h5,
.big {
  font-size: clamp($medium, 1.45vw, $big);
  line-height: normal;
  font-weight: 600;
  font-family: $font-title;
}

h4,
.large:not(input):not(textarea) {
  font-size: clamp($big, 1.7vw, $large);
  line-height: 134%;
  font-weight: 600;
  font-family: $font-base;
}

h3,
.massive {
  font-size: clamp($big, 1.7vw, $massive);
  line-height: normal;
  font-weight: 700;
  font-family: $font-title;
  text-transform: lowercase;
}

h2,
.huge {
  font-size: clamp(2.5rem, 3.25vw, $huge);
  line-height: 101%;
  font-weight: 700;
  font-family: $font-title;
  text-transform: lowercase;
  margin-bottom: 1.6rem;
}

h1,
.gigantic {
  font-size: clamp($huge, 4.7vw, $gigantic);
  line-height: normal;
  font-weight: 700;
  font-family: $font-title;
  text-transform: lowercase;
}

.rounded {
  border-radius: $rounded;
}

.grid {
  display: grid;
  gap: $gap;
}

.color-primary,
.color-blue-dark {
  color: $color-primary;
}

.color-secondary,
.color-blue-light {
  color: $color-secondary;
}

.color-tertiary,
.color-blue-lighter {
  color: $color-tertiary;
}

.bg-gray {
  background-color: $color-gray;
}

.img-container {
  border-radius: $rounded;
  overflow: hidden;
  display: flex;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.btn-primary {
  font-size: $small;
  line-height: normal;
  font-weight: 600;
  font-family: $font-title;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: .6rem 1rem;
  border-radius: $rounded;
  color: $color-secondary;
  background-color: $color-tertiary;
  transition: all .3s linear;
  text-transform: lowercase;

  &:hover,
  &.invert,
  &.reverse {
    color: white;
    background-color: $color-secondary;

    i {
      color: inherit;
    }
  }

  &.invert,
  &.reverse {
    &:hover {
      color: white;
      background-color: $color-primary;
    }
  }

  &.icon {
    padding: .45rem 1rem;
    font-size: 1.1rem;
  }

  &.rounded {
    border-radius: 50%;
    font-size: .7rem;
    padding: .6rem;
    width: 1.75rem;
    min-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
  }

  &.arrow {
    &::after {
      content: "\f061";
      font-family: $fontawesome;
      font-weight: bold;
      color: inherit;
      font-size: inherit;
    }
  }
}

.btn-secondary {
  font-size: .75rem;
  font-weight: 600;
  font-family: $font-title;
  line-height: normal;
  color: $color-secondary;
  width: fit-content;

  &:hover {
    color: $color-primary;
  }
}

.tag,
.benefit {
  padding: .75rem 2rem;
  color: $color-primary;
  font-size: 1.1rem;
  line-height: normal;
  background-color: $color-primary-light;
  border-radius: $rounded;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  overflow: hidden;
  margin: 0;

  &.small {
    font-size: 1rem;
    padding: .25rem 1rem;
  }
}

.category {
  padding: .3rem .8rem;
  font-size: .8rem;
  font-family: $font-title;
  font-weight: 600;
}

#post-content {
  padding: 0 3.5rem;
  margin-top: 3rem;

  @media (max-width:1199px) {
    padding: 0 2.5rem;
    margin-top: 2rem;
  }

  @media (max-width:767px) {
    padding: 0 1rem;
    margin-top: 1rem;
  }

  @media (max-width:576px) {
    padding: 0;
  }
}

p#breadcrumb {
  font-family: $font-title;
  color: $color-secondary;
  text-transform: lowercase;

  a {
    opacity: .7;
    &:hover {
      opacity: 1;
      color: $color-secondary;
    }
  }

  .breadcrumb_last {
    color: $color-primary;
  }
}

.show {
  display: block;
  animation: fadeIn 0.6s ease;
}

.hidden {
  animation: fadeOut 0.6s ease;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transition: all .7s ease;
  }
  to {
      opacity: 1;
      transition: all .7s ease;
  }
}

@keyframes fadeOut {
  from {
      opacity: 1;
      transition: all .7s ease;
  }
  to {
      opacity: 0;
      transition: all .7s ease;
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  min-height: 100%;
  height: 820px;

  iframe {
    width: 100%;
    height: 100%;

    #map {
      width: 100%;
    }
  }
}