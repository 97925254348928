@import "../../assets/styles/scss/variables";

.ts-wrapper {
  width: 100%;
  min-width: 15vw;

  &.single.input-active {
    .ts-control {
      background: $color-gray !important;
    }
  }

  .ts-control {
    background-color: $color-gray;
    border-radius: $rounded;
    border: solid $color-select-border 1px;
    justify-content: flex-start;
    padding: .8rem 1.25rem;
    line-height: normal;
    font-weight: 500;
    font-size: $medium;
    outline: none;

    .item {
      text-transform: none;
      font-weight: 500;
      font-size: $medium;
    }

    &:after {
      background: transparent;
      display: block;
      color: $color-secondary;
      content: url('../../assets/images/chevron-down-regular.svg');
      transform-origin: center;
    }
  }


  .ts-control,
  .ts-wrapper .ts-dropdown {
    border-radius: $rounded;
    background-color: $color-gray;
    font-weight: 500;
    font-size: $medium;
  }

  .ts-dropdown {
    .selected.active {
      color: white;
    }
    border-radius: 0 0 $rounded $rounded;
  }

  .ts-dropdown-content::-webkit-scrollbar {
    width: 12px;
    height: 8px;
    background-color: transparent;
  }
  
  .ts-dropdown-content::-webkit-scrollbar-thumb {
    background: $color-secondary;
    border-radius: $rounded;

  }

  &.dropdown-active {
    .ts-control {
      outline: none;
      background-color: $color-gray;
      
      &:after {
        transform-origin: center;
      }
    }
  }
}