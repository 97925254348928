@import "../../assets/styles/scss/variables";

.geodata-bloc {
  display: grid;
  grid-template-rows: auto 1fr;

  .img-container {
    position: relative;
    height: auto;
    padding-top: 68%;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    img {
      transition: all .2s linear;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .categories {
      padding: .5rem;
      padding-top: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: .3rem;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .infos {
    padding: .5rem;
    padding-right: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;

    .btn-primary {
      margin-left: 4.5rem;
      align-self: center;
    }

    .btn-secondary {
      margin-top: .5rem;
    }

    >div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  h3 {
    width: fit-content;

    a {
      color: $color-primary;

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &:hover {

    .img-container {
      img {
        transform: scale(1.05);
      }
    }

    h3 {

      a {
        color: $color-secondary;
      }
    }

    .btn-primary {
      color: white;
      background-color: $color-secondary;
    }
  }
}