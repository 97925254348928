@import "../../assets/styles/scss/variables";

.bloc-img {
  &.grid {
    display: flex;
    justify-content: space-between;
  }

  &:nth-child(even) {

    &.grid {
      flex-direction: row-reverse;
    }
  }

  .img-container {
    width: 50%;
  }

  .descr {
    width: 50%;
    padding: 3rem;
  }

  @media (max-width:991px) {
    flex-direction: column-reverse !important;

    .img-container, .descr {
      width: 100%;
    }

    .descr {
      padding: 1rem;
    }
  }
}