//
// Template Styles
// --------------------------------------------------

#main {
    padding-top: $headerHeight;
    z-index: 15;
}

.bloc-result {
    padding: 2rem;

    @media (max-width:767px) {
        padding: 1rem;
    }

    .btn-primary {
        margin-left: auto;
    }
}

#page-header.header-search {
    margin-top: 3rem;
    margin-bottom: 5rem;

    @media (max-width:1280px) {
        margin-top: 1.5rem;
        margin-bottom: 3rem;
    }
}

#page-search {

    form {
        position: relative;

        button {
            position: absolute;
            top: 50%;
            right: 1.1rem;
            transform: translateY(-50%);
            padding: 0;
            background-color: transparent;
            color: $color-primary;
        }
    }

    .results {
        margin-top: 3rem;
        gap: 2rem;
    }
}

.services.grid {
    gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width:1441px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width:1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:576px) {
        display: flex;
        flex-direction: column;
    }
}

.geodatas.grid {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 3rem;
    column-gap: 1.3rem;

    @media (max-width:1350px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width:1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:767px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

.blocs.grid {
    gap: 5rem;

    @media (max-width:1199px) {
        gap: 3rem;
    }
}

.municipalities.grid {
    gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);

    @media (min-width:1920px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width:1499px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width:1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:576px) {
        display: flex;
        flex-direction: column;
    }
}

.municipality-bloc {
    display: grid;
    grid-template-columns: calc(27% - .4rem) calc(73% - .4rem);
    padding: 1rem;
    gap: .8rem;
    position: relative;

    .badge {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;

        img {
            object-fit: contain;
            width: fit-content;
            height: auto;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 78%;
        align-items: center;


        h3 {
            text-transform: none;
            color: $color-primary;
            font-size: clamp(1rem, 1vw, 1.6rem);
        }

        .btn-primary {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }

    &.municipality-link {
        .content {
            &:hover {
                h3 {
                    color: $color-secondary;
                }

                .btn-primary {
                    background-color: $color-secondary;
                    color: white;
                }
            }
        }
    }
}

.info-blocs.grid {
    row-gap: 3rem;
    column-gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width:1920px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width:991px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:576px) {
        display: flex;
        flex-direction: column;
    }
}

.info-bloc {

    h3 {
        margin: .5rem 0;
    }

    h3,
    p {
        max-width: 95%;
    }
}

#single-service {

    section {
        margin: 9rem 0;

        &:first-child {
            margin-top: 0;
        }

        @media (max-width:991px) {
            margin: 5rem 0;
        }
    }

    section#map {

        .municipalities.grid {
            margin-top: 3rem;
        }
    }

    section#info-blocs {
        
        form {
            max-width: 66%;
            margin-bottom: 3rem;

            @media (min-width:1920px) {
                max-width: calc(50% - 0.75rem);
            }

            @media (max-width:1199px) {
                max-width: 100%;
                margin-bottom: 2rem;
            }
        }
    }

    section.blocs {

        &:nth-child(even) {

            .bloc-img {
                flex-direction: row-reverse;
            }
        }

    }

    .fitlers-container {
        padding-top: 4rem;
    }
}

#page-homepage {

    #services {
        margin-top: 5rem;
        margin-bottom: 8rem;

        @media (max-width:1199px) {
            margin-top: 3rem;
            margin-bottom: 5rem;
        }
    }
}

#page-blog {
    .fitlers-container {
        width: 70%;
        padding-bottom: 3rem;
        transition: width .3s ease;

        .filters-select {
            width: calc(35% - 1.25rem);
        }
    }

    #posts-grid {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
        transition: width .3s ease;
    }

    @media(max-width:1599px) {

        .fitlers-container,
        #posts-grid {
            width: 80%;
        }
    }

    @media(max-width:1440px) {

        .fitlers-container,
        #posts-grid {
            width: 90%;
        }
    }

    @media(max-width:1199px) {

        .fitlers-container,
        #posts-grid {
            width: 100%;
        }

        .fitlers-container {
            .filters-select {
                width: calc(40% - 1.25rem);
            }
        }
    }

    @media(max-width:991px) {
        .fitlers-container {
            .filters-select {
                width: calc(45% - 1.25rem);
            }
        }
    }

    @media(max-width:767px) {
        .fitlers-container {
            .filters-select {
                width: calc(100%);
            }
        }
    }
}

#page-geodatas {
    .fitlers-container {
        .filters {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.3rem;
            padding-bottom: 3rem;
            transition: width .3s ease;

            #search-post {
                grid-column: span 2;
            }

            .filters-select {
                grid-column: span 3;
                display: grid;
                gap: 1.3rem;
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;

                .filters-select {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

#page-about {
    .office-section {
        margin-top: 10rem;

        h2 {
            width: 25%;
        }

        @media (max-width:1199px) {
            margin-top: 5rem;

            h2 {
                width: 100%;
            }
        }
    }
}

#error-template {
    padding: 0;

    .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .error-content {
            h1 {
                font-size: clamp(4.7rem, 9.7vw, 7.5rem);
                line-height: 7rem;
            }

            background-image: url(../../assets/images/map.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                height: 350px;
                transition: all 0.6s ease;

                h1 {
                    line-height: 5rem;
                }
            }
        }

        .error-actions {
            margin-top: 6rem;
            display: flex;
            justify-content: center;
            gap: 3rem;

            @media (max-width:767px) {
                margin-top: 4rem;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }

            a {
                display: flex;
                flex-direction: row;
                gap: 1rem;
            }
        }

        @media (max-width:767px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}