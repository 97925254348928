//
// Footer Styles
// --------------------------------------------------

section#related-geodatas {
	margin: 9rem 0;

	@media (max-width:991px) {
		margin: 5rem 0;
	}

	.grid:not(.geodatas) {
		grid-template-columns: 33% auto;

		@media (max-width:1280px) {
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
	}

	.geodatas.grid {
		grid-template-columns: repeat(2, 1fr);

		@media (max-width:767px) {
			display: flex;
			flex-direction: column;
		} 
	}
}

section#footer-cta {
	margin: 9rem 0;
	position: relative;

	@media (max-width:991px) {
		margin: 5rem 0;
	}

	img.shape {
		position: absolute;
		z-index: -1;
		right: -12rem;
		top: -6rem;
		width: 23rem;
		height: auto;
	}

	.wrapper {
		grid-template-columns: 40% 50%;
		justify-content: space-between;
		gap: 1.5rem;

		@media (max-width:1199px) {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 4rem;
		}

		&.full {
			display: flex;
			flex-direction: column;

			.benefits {
				width: 66%;

				@media (max-width:1199px) {
					width: 83%;
				}

				@media (max-width:767px) {
					width: 100%;
				}
			}
		}
	}

	.benefits {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.key-figures {
		gap: 4rem;
		row-gap: 2rem;
		grid-template-columns: repeat(3, 1fr);
		justify-content: flex-start;

		.key-figure {
			text-align: center;

			p {
				margin: 0;
			}

			.number {
				font-size: 7.7rem;
				line-height: 143.6%;
			}

			.label {
				font-size: 1.1rem;
				font-weight: 600;
				line-height: 143.6%;
				margin-top: -2rem;
			}
		}

		@media (max-width:1750px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width:1199px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (max-width:991px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width:576px) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

#footer {
	background-color: white;
	margin-top: 9rem;

	@media (max-width:991px) {
		margin-top: 5rem;
	}

	#footer-top {
		padding-top: 4rem;
		padding-bottom: 2rem;

		.logo {
			margin-bottom: 1.8rem;
			width: 18rem;
			max-width: 45%;
		}

		.bottom-part {
			display: grid;
			grid-template-columns: 33% auto;
			gap: 1rem;
			justify-content: space-between;
			align-items: flex-end;

			@media (max-width:991px) {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 3rem;

				.left {
					max-width: 55%;
				}

				.partners {
					margin-left: auto;
				}
			}

			@media (max-width:576px) {

				.left {
					max-width: 100%;
				}
			}

			.left {
				display: flex;
				flex-wrap: wrap;
				row-gap: .7rem;
				column-gap: .5rem,
			}
			.socials {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				flex-wrap: wrap;
				gap: .5rem;

				li {

					.btn-primary {
						height: 100%;
					}
				}
			}

			.partners {
				display: flex;
				flex-wrap: wrap;
				gap: 1.2rem;
				align-items: center;
				justify-content: flex-end;

				.partner-bloc {
					height: 2.7rem;
					width: auto;
					max-width: 9rem;

					img {
						height: 100%;
						width: 100%;
						object-fit: contain;
					}
				}
			}
		}
	}

	#footer-bottom {
		background-color: $color-primary;
		color: $color-gray;
		font-size: $xsmall;
		line-height: normal;
		font-weight: 400;
		padding-top: 1.8rem;
		padding-bottom: .9rem;

		a {
			color: inherit;
		}

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width:767px) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 1rem;
			}
		}
	}
}

.powered {
	position: relative;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
		#logo-alt-footer-trois {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-deux {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-un {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		&:after {
			width: 100px;
		}
	}
	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;
		#logo-alt-footer-trois {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-deux {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-un {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 83px;
		width: 0px;
		height: 2px;
		background-color: #FFD94A;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}
