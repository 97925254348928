@import "../../assets/styles/scss/variables";

.tabs-wrapper {
  display: flex;

  .tabs {
    display: grid;
    grid-template-columns: calc(30% - 2.85rem) calc(70% - 2.85rem);
    transition: all 0.5s ease; 
    gap: 5.7rem;
    width: 100%;

    @media (max-width:1920px) {
      grid-template-columns: calc(30% - 2.6rem) calc(70% - 2.6rem);
      transition: all 0.5s ease; 
      gap: 5.2rem;
    }

    @media (max-width:1599px) {
      grid-template-columns: calc(35% - 1.6rem) calc(65% - 1.6rem);
      transition: all 0.5s ease; 
      gap: 3.2rem;
    }

    @media (max-width:1199px) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .tab-titles {
      padding: 2.56rem 0;
      display: flex;
      flex-direction: column;
      gap: 1.7rem;

      @media (max-width:1199px) {
        flex-direction: row;
        column-gap:2.2 ;
        row-gap: 1rem;
        flex-wrap: wrap;
      }

      .tab-title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width:1199px) {
          width: fit-content;
        }

        h3 {
          text-transform: inherit !important;
          width: 100%;
          display: flex;
          font-size: clamp(1.2rem, 1.7vw, 1.6rem);
          font-weight: 700;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          width: 100%;
  
          @media (max-width:1199px) {
            font-size: clamp(1rem, 1.7vw, 1.6rem);
            gap: 0.6rem;
          }
        }

        &.show h3,
        &:hover h3 {
          color: $color-secondary;
        }

        &:hover {
          .btn-primary {
            color: #fff;
            background-color: #5cbed8;
          }
        }
      }
    }

    .tab-contents {
      .tab-content {
        display: none;
        animation: fadeOut 0.5s forwards;

        .tab-logo {
          max-width: 250px;

          img {
            width: 100%;
            max-height: 90px;
            object-fit: contain;
  
          }
        }

        &.show {
          display: flex;
          gap: 2.6rem;
          flex-direction: column;
          background-color: $color-gray;
          padding: 2.2rem;
          animation: fadeIn 0.5s forwards;

          &.offices {
           
            .grid {
              display: grid;
              gap: 3rem;
              grid-template-columns: 1fr 1fr;
              width: fit-content;
            }
          }

          .content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1rem;
            row-gap: 3rem;

            @media (max-width:1920px) {
              grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width:1599px) {
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
      }
    }
  }
}