@import "../../assets/styles/scss/variables";

#page-header.header-geodata {
  position: relative;

  img.shapes {
    position: absolute;
    z-index: -1;
    top: calc(-3rem - $headerHeight - 1.3rem);
    right: -11rem;
    width: 24rem;
    max-width: 60vw;
  }

  .column {
    margin-top: 3rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;

    @media (max-width:1280px) {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }

    h1, h2.large, p.descr {
      max-width: 75%;

      @media (max-width:991px) {
        max-width: 100%;
      }
    }
  }

  .categories {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: .5rem;
  }

  .category {
    font-size: 1rem;
  }

  h1 {
    margin-bottom: .5rem;
  }

  p.descr {
    margin-top: $gap;
  }

  .buttons {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
  }
}