@import "../../assets/styles/scss/variables";

a.service-bloc {
  padding: 2rem;
  display: flex;
  flex-direction: column;

  @media (max-width:576px) {
    padding: 1rem;
  }

  h3.massive {
    margin-bottom: .9rem;
  }

  h3, p {
    color: $color-primary;
  }

  .btn-primary {
    margin-left: auto;
    margin-top: auto;
  }

  &:hover {
    
    h3 {
      color: $color-secondary;
    }

    p {
      color: $color-primary;
    }

    .btn-primary {
      color: white;
      background-color: $color-secondary;
    }
  }
}