@import "../../assets/styles/scss/variables";

#languages-switcher {
  font-size: $xsmall;
  line-height: normal;
  color: $color-text;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  transition: all .2s linear;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  
  .current {
    background-color: $color-primary;
    color: $color-text;
    padding: .7rem 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $small;
    transition: all .2s linear;
  }

  .languages-list {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    display: none;

    li {
      font-size: $xsmall;

      a {
        display: flex;
        padding: .3rem 1.3rem;
        background-color: $color-primary;
        color: $color-text;
      }

      &:hover {
        
        a {
          background-color: $color-gray-darker;
          color: $color-primary;
        }
      }
    }
  }

  &:hover {

    .current {

      i {
        transform: rotate(180deg);
      }
    }

    .languages-list {
      display: initial;
    }
  }
}