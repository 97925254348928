@import "../../assets/styles/scss/variables";

#page-header.header-service {
  position: relative;

  img.shapes {
    position: absolute;
    z-index: -1;
    top: calc(-3rem - $headerHeight - 1.3rem);
    right: -11rem;
    width: 24rem;
    max-width: 60vw;
  }

  .column {
    margin-top: 3rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;

    @media (max-width:1280px) {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }

    h1, h2.large, p.descr {
      max-width: 75%;

      @media (max-width:991px) {
        max-width: 100%;
      }
    }
  }

  h1 {
    margin-bottom: .5rem;
  }

  p.descr {
    margin-top: $gap;
  }

  .btn-primary {
    margin-top: 1.5rem;
  }
}