//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: transparent;
	display: flex;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 200;
	padding: 1.9rem 0;
	transition: all 0.4s linear;

	&.sticky {
		background-color: white;
		padding: 1rem 0;

		#logo {
			max-width: 15rem;
			transition: all 0.4s linear;

			img {
				width: 100%;
			}
		}

		@media (max-width:1440px) {

			#logo {
				max-width: 12rem;
			}
		}
	}


	#header-content {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: flex-start;
		gap: 4rem;

		@media (max-width:1280px) {
			justify-content: space-between;
			gap: 1rem;
		}
	}

	#menu-mobile-trigger {
		font-size: 31px;
		display: none;
		position: relative;

		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-primary;

			&:not(:first-child) {
				margin-top: 6px;
			}
		}

		@media (max-width:1280px) {
			display: block;
		}

		&.is-active {
			.trigger-bar {
				&:nth-child(1) {
					transform: rotate(45deg) translate(5px, 5px);
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:nth-child(3) {
					transform: rotate(-45deg) translate(7px, -7px);
				}
			}
		}
	}

	#logo {
		max-width: 17rem;
		width: 100%;
		transition: all 0.4s linear;

		a {
			display: flex;
		}

		@media (max-width:1441px) {
			max-width: 13rem;
		}

		@media (max-width:767px) {
			max-width: 10rem;
		}
	}

	#header-extras {
		margin-left: auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: .5rem;

		@media (max-width:1280px) {
			display: none;
		}

		.global-search {
			display: flex;
			overflow: hidden;
			align-items: center;

			button {
				padding: .6rem;
				aspect-ratio: 1;
			}

			form {
				display: flex;
				overflow: hidden;
				width: 0;
				transition: all .3s linear;
				background-color: $color-tertiary;

				input[type="search"] {
					background-color: transparent;
					padding: 0.2rem 1rem;
					line-height: normal;
					border-radius: 0;
					border: none;
					font-size: .7rem !important;

					&::placeholder {
						color: $color-secondary;
						opacity: 1;
						font-size: .7rem !important;
					}
				}
			}

			&.is-open {
				border-radius: $rounded;

				form {
					width: auto;
				}

				.btn-primary {
					border-radius: 0;
				}
			}
		}
	}

	#menu {
		transition: all 0.4s linear;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: center;

		@media (max-width:1199px) {
			gap: 0.5rem;
		}

		#menu-main-container {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;

			ul {
				display: flex;
				align-items: center;
				gap: 2rem;
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: left;

				li {
					display: flex;
					align-items: center;
					position: relative;

					a {
						display: block;
						font-size: $small;
						font-weight: 600;
						color: $color-primary;
						display: flex;
						align-items: center;

						&:hover {
							color: $color-link-hover;
						}
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						>a {
							color: $color-secondary;
						}

						.sub-menu {
							li {
								a {
									color: $color-primary;
								}
							}
						}
					}

					.sub-menu {
						display: flex;
						flex-direction: column;
						top: 100%;
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -40px;
						transform: scale(0.95) translateY(0);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;
						border-radius: 0.22222rem;

						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							border-bottom: 1px solid $color-gray-lighter;

							a {
								display: block;
								color: $color-primary;
								background-color: $color-gray-darker;
								padding: 20px 35px;

								&:hover {
									color: $color-link-hover;
									background-color: $color-gray-darker;
								}
							}

							&:last-child {
								margin-bottom: 0;
								border-bottom: 0px solid $color-gray-lighter;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-primary;
								}
							}
						}

						li.anchor-link {
							a {
								color: $color-primary;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-primary;
								}
							}

							a:hover {
								color: $color-secondary;
							}
						}

						li.menu-file {

							&:after {
								content: "\f08e";
								font-family: 'Font Awesome 6 Regular';
								position: absolute;
								right: 10px;
								opacity: 0;
								transition: all 0.4s ease;
							}
						}

						li.menu-file {
							&:hover:after {
								opacity: 1;
								transition: all 0.4s ease;
							}
						}
					}

					&.menu-item-has-children {
						margin-right: 3.17rem;

						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f078';
							display: block;
							position: absolute;
							top: 50%;
							right: -2rem;
							font-weight: 400;
							transform: translateY(-50%);
							transform-origin: center;
							color: $color-primary;
							transition: transform 0.25s;
						}

						&:hover {
							&:after {
								transform: translateY(-50%) rotate(180deg);
							}

							.sub-menu {
								height: auto;
								opacity: 1;
								transform: scale(1) translateY(0);
							}
						}
					}
				}
			}
		}
	}

	#extra-menu {
		display: flex;
		flex-direction: row;
		height: 50%;
		align-items: flex-start;
		position: absolute;
		top: 5px;

		ul {
			display: flex;
			gap: 1.64rem;
			flex-direction: row;
			list-style: none;
			text-align: right;
			float: right;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-inline-start: 0px;

			li {
				position: relative;
				color: $color-red;
				transition: all ease 0.3s;
				border-bottom: solid transparent 1px;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					transform: scaleX(0);
					border-radius: 5px;
					height: 0.05em;
					bottom: 0;
					left: 0;
					transform-origin: bottom right;
					background: $color-primary;
					transition: transform .25s ease-out;
				}

				&:before {
					padding: 0 0.6rem 0 0;
					font-family: "Font Awesome 6 Pro";
					content: "\f054";
				}

				&:hover {
					color: $color-primary;
					transition: all ease 0.3s;

					&:after {
						transform: scaleX(1);
						transform-origin: bottom left;
						transition-duration: .3s;
					}

					a {
						color: $color-primary;
						transition: all ease 0.3s;
					}
				}
			}

			@media (max-width:1280px) {
				a {
					font-size: 1.1rem;
				}
			}
		}
	}
}

.mm-ocd--open {
	bottom: 0;
	top: initial;
	height: calc(100vh - $headerHeight);

	.mm-ocd__backdrop {
		z-index: -1;
	}

	#menu-principal:before {
		border-top: 0;
	}

	.mm-ocd__content {
		max-width: 65vw;

		.mm-spn.mm-spn--light {
			background: white;

			&::after,
			&::before {
				opacity: 1;
				color: $color-primary;
			}

			li {
				&::after {
					border-color: $color-primary;
				}

				&:last-of-type {
					&::after {
						border-top: 0px solid;
					}
				}

				a {
					color: $color-primary;
					padding-top: 1rem;
					padding-bottom: 1rem;
					font-weight: 600;
				}

				&.current_page_item a,
				&.current-menu-parent a {
					color: $color-secondary;
				}
			}

			.menu-item-has-children {
				&::before {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-family: "Font Awesome 6 Pro";
					content: '\f054';
					height: 100%;
					width: 100%;
					top: 0;
					right: 30px;
					z-index: 10;
					position: absolute;
					transform: rotate(0) translate(0);
					border-top: none;
					border-right: none;
					opacity: 1;
					color: $color-primary;
				}

				a {
					&:after {
						border-right: 0px solid;
						opacity: 1;
					}
				}

				.sub-menu {
					z-index: 20;

					a {
						color: $color-primary;
					}

					.current-menu-item {
						a {
							color: $color-secondary;
						}
					}
				}
			}
		}

		#menu-main-container {
			#menu-principal {
				height: auto;
				position: relative;
			}

			#extra-menu {
				height: auto;

				#menu-extra-menu {
					position: relative;
					left: 0;
					bottom: 0;
					top: auto;

					&:before {
						border-top: 0;
					}

					li {
						&:before {
							visibility: hidden;
						}

						a {
							color: $color-primary;
						}
					}
				}
			}
		}

		@media (max-width:767px) {
			#menu-principal {
				li {
					&:last-of-type {
						&::after {
							content: "";
							display: block;
							margin-left: var(--mm-spn-item-indent);
							border-top: 1px solid;
							opacity: .15;
						}
					}
				}
			}
		}
	}
}