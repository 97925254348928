@import "../../assets/styles/scss/variables";

#search-post {
  width: 100%;
  min-width: calc(80vw / 4);
  
  .input-group {
    position: relative;
    display: flex;
    align-items: center;

    input {
      background-color: $color-gray;
      border-radius: $rounded;
      border: solid $color-select-border 1px;
      justify-content: flex-start;
      padding: .8rem 2.95rem .8rem 1.2rem;
      font-size: $medium;
      line-height: normal;
    }

  }

  i {
    position: absolute;
    right: 1.25rem;
    color: $color-primary;
    opacity: .5;
  }
}