@import "../../assets/styles/scss/variables";

#page-header.header-news {
  position: relative;

  img.shapes {
    position: absolute;
    z-index: -1;
    top: calc(-3rem - $headerHeight - 1.3rem);
    right: -11rem;
    width: 24rem;
    max-width: 60vw;
  }

  .smaller-container {
    margin-top: 3rem;
    margin-bottom: 5rem;

    @media (max-width:1280px) {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }
  }

  .categories {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: .5rem;
  }

  .category {
    font-size: 1rem;
  }

  h1 {
    margin-bottom: 3rem;
  }

  .img-container {
    max-width: 55%;
    margin-inline: auto;
  }
}