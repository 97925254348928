@import "../../assets/styles/scss/variables";

#page-header.header-standard {
  position: relative;

  img.shapes {
    position: absolute;
    z-index: -1;
    top: calc(-3rem - $headerHeight - 2rem);
    right: -10rem;
    width: 36rem;
    max-width: 70vw;
  }

  .grid {
    gap: 3.8rem;
    grid-template-columns: 41% 58%;
    justify-content: space-between;
    margin-top: 6rem;
    margin-bottom: 5rem;

    @media (max-width:1280px) {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    @media (max-width:1199px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width:991px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;
    }
  }

  .column {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;

    @media (max-width:1280px) {
      margin-top: 1.5rem;
      margin-bottom: 3.5rem;
    }

    h2.large, p.descr {
      max-width: 75%;

      @media (max-width:991px) {
        max-width: 100%;
      }
    }
  }

  .tags {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: .5rem;
  }

  h1 {
    margin-bottom: .5rem;
  }

  p.descr {
    margin-top: $gap;
  }

  .btn-primary {
    margin-top: 1.5rem;
  }

  .right {
    max-width: calc(100% - 3.8rem);

    @media (max-width:1199px) {
      max-width: 100%;
    }
  }
}