@import "../../assets/styles/scss/variables";

.accordion {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    opacity: .3;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  .accordion-title {
    padding: $large $xsmall;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $small;
    cursor: pointer;

    &.is-open {

      i {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-content {
    display: none;
    padding: 0 $xsmall;
    padding-bottom: $large;
  }
}