@import "../../assets/styles/scss/variables";

.blog-bloc {
  display: grid;
  width: 100%;
  grid-template-columns: calc(35% - 1.25rem) calc(65% - 1.25rem);
  grid-template-rows: 1fr;
  gap: 2.5rem;

  .img-container {
    position: relative;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    img {
      transition: all .2s linear;
    }
  }

  .infos-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    width: 100%;
    justify-content: center;

    .tags {
      display: flex;
      gap: 1rem;

      .tag {
        font-size: $xsmall;
        font-style: normal;
        font-weight: 400;
        text-transform: lowercase;
      }

      ul {
        display: flex;
        gap: 1rem;
        margin: 0;
        padding: 0;
      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      h3 {
        width: fit-content;
        color: $color-primary;

        a {
          color: $color-primary;

          &:hover {
            color: $color-secondary;
          }
        }
      }

      .news-excerpt {
        width: 100%;
        color: $color-primary;
      }

      .button-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
          padding: 0;
        }
      }
    }
  }

  &:hover {
    .img-container {
      img {
        transform: scale(1.05);
      }
    }

    .infos-container {
      .infos {
        h3 {
          color: $color-secondary;
        }
      }
    }

    .btn-primary {
      color: white;
      background-color: $color-secondary;
    }
  }

  @media(max-width:1199px) {
    grid-template-columns: calc(40% - 1.25rem) calc(60% - 1.25rem);
    transition: width .3s ease;
  }

  @media(max-width:991px) {
    grid-template-columns: calc(45% - 1.25rem) calc(55% - 1.25rem);
    transition: width .3s ease;
  }

  @media(max-width:767px) {
    transition: width .3s ease;
    display: flex;
    flex-direction: column;
  }
}