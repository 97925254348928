//
// Variables
// --------------------------------------------------


//== Colors
$color-primary: #0C2265;
$color-blue-dark: $color-primary;
$color-primary-light: #E7E9F0;
$color-secondary: #5CBED8;
$color-blue-light: $color-secondary;
$color-tertiary: #E7F5F9;
$color-blue-lighter: $color-tertiary;
$color-gray: #F7F8FA;


$color-gray-light: $color-gray;
$color-gray-darker: #1d1d1d;
$color-gray-dark: #4c4c4c;
$color-gray-lighter: #eeeeee;
$color-red: #d63e3e;
$color-green: #2ee255;
$color-link: $color-secondary;
$color-link-hover: darken($color-link, 10%);
$color-text: $color-primary;
$color-select-border: rgba(12, 34, 101, 0.10);


//== Typography
$font-base: 'Montserrat', sans-serif;
$font-title: "all-round-gothic", sans-serif;
$text-base-size: 18px;
$text-base-line-height: 1.5em;
$title-base-line-height: 1.4em;
$fontawesome: "Font Awesome 6 Pro";
$fontawesome-brand: "Font Awesome 6 Brands";


//== Sizes
$xsmall: 14px;
$small: 16px;
$regular-mobile: $small;
$regular: 20px;
$medium: 1.2rem; //24px
$big: 1.4rem; //28px
$large:  1.6rem; //32px
$massive: 1.6rem; //32px
$huge: 3.1rem; //62px
$gigantic: 4.5rem; //90px


//== Margins
$margin-base: 75px;
:root {
  --headerHeight: 0;
}
$headerHeight: var(--headerHeight);


//Styling
$rounded: .5rem;
$gap: 1rem;